export enum GAME_EVENT_TYPE {
  attack = "attack",
  dealt_damage = "dealt damage",
  game_ended = "game ended",
  state_actions = "state actions",
  activated_trigger = "activated trigger",
}

export const GAME_EVENT_TYPES = [
  GAME_EVENT_TYPE.attack,
  GAME_EVENT_TYPE.dealt_damage,
  GAME_EVENT_TYPE.game_ended,
  GAME_EVENT_TYPE.state_actions,
  GAME_EVENT_TYPE.activated_trigger,
] as const;

export type GameEventType = typeof GAME_EVENT_TYPES[number];