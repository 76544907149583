import React from "react";
import styled from "styled-components";

import { Character } from "../../logic/Character/Character";
import { CharacterImage } from "../CharacterImage/CharacterImage";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Square = styled.div<{
  size: number;
}>`
  width: ${(p) => p.size}rem;
  height: ${(p) => p.size}rem;
`;

const Stats = styled.div<{
  size: number;
}>`
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding: ${(p) => p.size / 4}rem ${(p) => p.size / 2}rem;
  box-sizing: border-box;

  font-size: ${(p) => p.size * (2 / 3)}rem;
`;

const CharacterCard = ({
  character,
  big = false,
  reverse = false,
}: {
  character: Character;
  big?: boolean;
  reverse?: boolean;
}) => (
  <Card>
    <Square size={big ? 4 : 3}>
      <CharacterImage
        src={character.image}
        alt="A teeny-tiny mage"
        reverse={reverse}
      />
    </Square>
    <Stats size={big ? 1 : 3 / 4}>
      <span>{character.attack}⚔</span>
      <span>{character.health}♥</span>
    </Stats>
  </Card>
);

export default CharacterCard;
