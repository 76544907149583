export enum GAME_ACTION_TYPE {
  AttackAction = "AttackAction",
  DealDamageAction = "DealDamageAction",
  PerformStateBasedActionsAction = "PerformStateBasedActionsAction",
}

export const GAME_ACTION_TYPES = [
  GAME_ACTION_TYPE.AttackAction,
  GAME_ACTION_TYPE.DealDamageAction,
  GAME_ACTION_TYPE.PerformStateBasedActionsAction,
] as const;

export type GameActionType = typeof GAME_ACTION_TYPES[number];
