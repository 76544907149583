import React from "react";
import styled from "styled-components";

import { Character } from "../../logic/Character/Character";

import CharacterCard from "../CharacterCard/CharacterCard";

const Row = styled.div<{
  reverse?: boolean;
}>`
  display: flex;
  align-items: flex-end;
  flex-direction: ${(p) =>
    p.reverse ? "row-reverse" : "row"};
  gap: 1rem;
`;

const Team = ({
  characters,
  reverse = false,
}: {
  characters: Character[];
  reverse?: boolean;
}) => (
  <Row reverse={reverse}>
    {characters[0] && (
      <CharacterCard
        character={characters[0]}
        reverse={reverse}
        big
      />
    )}
    {characters.slice(1).map((character, index) => (
      <CharacterCard
        character={character}
        reverse={reverse}
        key={index}
      />
    ))}
  </Row>
);

export const Battlefield = ({
  left,
  right,
}: {
  left: Character[];
  right: Character[];
}) => (
  <>
    {left[0] && <Team characters={left} reverse />}
    {right[0] && <Team characters={right} />}
  </>
);
