import { Character } from "../Character/Character";
import { CharacterQuery } from "./CharacterQuery";
import { CHARACTER_QUERY_TYPE } from "./CharacterQuery.definitions";

export const Query = (character: Character, queries: CharacterQuery[]) =>
  queries.every((query) => {
    switch (query.kind) {
      case CHARACTER_QUERY_TYPE.side:
        return character.position.side === query.target;
      case CHARACTER_QUERY_TYPE.position:
        return character.position.position === query.target;
      default:
        throw new Error("Impossible query");
    }
  });
