import styled from "styled-components";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 5rem;
  height: 2rem;

  background-color: #0088ff;

  border-radius: 0.25rem;

  &:disabled {
    background-color: #004488;
  }
`;

export default Button;
