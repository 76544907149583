import { GameAction } from "../GameAction/GameAction";
import { GameEvent } from "../GameEvent/GameEvent";
import { GAME_EVENT_TYPE } from "../GameEvent/GameEvent.definitions";
import { FilterEvents } from "../GameEventFilter/GameEventFilter.operations";
import { Character } from "./Character";

export const CharacterReducer: (event: GameEvent) => (
  state: Character
) => {
  state: Character;
  reaction: GameAction[][];
  explanation: GameEvent[];
} = (event: GameEvent) => (state: Character) => {
  const activatedTriggers = state.triggers.filter(
    (trigger) => FilterEvents(event, trigger.condition)
  );

  return {
    state,
    reaction: activatedTriggers.map(
      (trigger) => trigger.then
    ),
    explanation: activatedTriggers.map((trigger) => ({
      kind: GAME_EVENT_TYPE.activated_trigger,
      trigger,
    })),
  };
};
