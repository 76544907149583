import { GameEvent } from "../logic/GameEvent/GameEvent";
import { GAME_EVENT_TYPE } from "../logic/GameEvent/GameEvent.definitions";
import {
  InterfaceAction,
  InterfaceActionKind,
  InterfaceState,
} from "../types/InterfaceState";
import {
  StyleAnimation,
  StyleAnimationTemplate,
} from "../views/animation-system";
import Animations from "../views/animations";

const start = (
  template: StyleAnimationTemplate,
  start: number
) => ({
  id: `${template.id}-${Date.now()}`,
  keyframes: template.keyframes,
  timing: template.timing,
  start: start,
  duration: template.duration,
});

export function UIReducer(
  state: InterfaceState,
  action: InterfaceAction
): InterfaceState {
  const shouldAnimate = (event: GameEvent) => {
    switch (event.kind) {
      case GAME_EVENT_TYPE.attack:
        return true;
      case GAME_EVENT_TYPE.dealt_damage:
        return true;
      case GAME_EVENT_TYPE.activated_trigger:
        return true;
      default:
        return false;
    }
  };

  const animateEvent = (event: GameEvent) => {
    switch (event.kind) {
      case GAME_EVENT_TYPE.attack:
        return [Animations.bump];
      case GAME_EVENT_TYPE.dealt_damage:
        return [Animations.big];
      case GAME_EVENT_TYPE.activated_trigger:
        return [Animations.shine, Animations.big];
      default:
        throw Error(
          "Tried to animate event without animation support"
        );
    }
  };

  const getEndtime = (
    animations: StyleAnimation[] | undefined
  ) =>
    animations
      ? Math.max(
          ...animations.map(
            (animation) =>
              animation.start + animation.duration
          )
        )
      : Date.now();

  switch (action.kind) {
    case InterfaceActionKind.Present:
      const remaining = (animation: StyleAnimation) =>
        animation.start + animation.duration - Date.now();

      const reduced = action.events
        .filter(shouldAnimate)
        .reduce(
          (acc, value) => [
            ...acc,
            [
              ...animateEvent(value).map((animation) =>
                start(animation, getEndtime(acc.at(-1)))
              ),
            ],
          ],
          [] as StyleAnimation[][]
        )
        .reduce((acc, value) => acc.concat(value), []);

      return {
        ...state,
        animations: state.animations
          .concat(reduced)
          .filter((a) => remaining(a) > 0),
      };
  }
}
