import { GameReducer } from "../logic/GameState/GameState.reducer";
import {
  ApplicationState,
  ApplicationAction,
} from "../types/Application";
import { InterfaceActionKind } from "../types/InterfaceState";
import { UIReducer } from "./UIReducer";

export function ApplicationReducer(
  app: ApplicationState,
  appAction: ApplicationAction
): ApplicationState {
  switch (appAction.kind) {
    case "Continue":
      if (app.resolver.type === "Paused") {
        const { state, explanation } = GameReducer(
          app.game
        );

        const ui = UIReducer(app.interface, {
          kind: InterfaceActionKind.Present,
          events: explanation,
        });

        if (ui.animations.length <= 0) {
          return {
            ...app,
            game: state,
            resolver: {
              type: "Paused",
            },
            interface: ui,
          };
        }

        return {
          ...app,
          game: app.game,
          resolver: {
            type: "Running",
            nextState: state,
          },
          interface: ui,
        };
      }

      throw Error("Invalid application reducer input");
  }
}
