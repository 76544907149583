export enum EVENT_FILTER_TYPE {
  event_kind = "event kind",
  game_state = "game state",
}

const EVENT_FILTER_TYPES = [
  EVENT_FILTER_TYPE.event_kind,
  EVENT_FILTER_TYPE.game_state,
] as const;

export type EventFilterType =
  typeof EVENT_FILTER_TYPES[number];
