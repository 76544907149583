import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const globalStyle = createGlobalStyle`
	${normalize}

	* {
		padding: 0;
		margin: 0;
		color: white;
	}

	body {
		font-family: sans-serif;
		font-size: 16px;
		background-image: linear-gradient(
			to bottom,
			#000000 0%,
			#110022 60%
		);
	}

	button {
		border: 0;
	}
`;

export default globalStyle;
