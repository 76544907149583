import { SIDE } from "../Position/Position.definitions";

export enum CHARACTER_QUERY_TYPE {
  side = "side",
  position = "position",
}

interface BaseCharacterQuery {
  kind: CHARACTER_QUERY_TYPE;
}

export interface SideQuery extends BaseCharacterQuery {
  kind: CHARACTER_QUERY_TYPE.side;
  target: SIDE;
}

export interface PositionQuery extends BaseCharacterQuery {
  kind: CHARACTER_QUERY_TYPE.position;
  target: number;
}

// export type CharacterQuery = SideQuery | PositionQuery;
