import styled, { css } from "styled-components";

export const CharacterImage = styled.img<{
  reverse?: boolean;
}>`
  image-rendering: pixelated;
  width: 100%;
  height: 100%;

  ${(p) =>
    !p.reverse &&
    css`
      transform: scaleX(-1);
    `}
`;
