import { v4 as uuidv4 } from "uuid";
import React, { useCallback, useState } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";

import styled from "styled-components";

import Button from "./components/Button/Button";
import Navbar from "./components/Navbar/Navbar";
import GlobalStyle from "./globalStyles";

import { ApplicationReducer } from "./models/ApplicationReducer";
import { ApplicationState } from "./types/Application";

import Fight from "./views/Fight";
import characterImage from "./assets/test-character.png";

import { EVENT_FILTER_TYPE } from "./logic/GameEventFilter/GameEventFilter.definitions";
import { GAME_EVENT_TYPE } from "./logic/GameEvent/GameEvent.definitions";
import { GAME_ACTION_TYPE } from "./logic/GameAction/GameAction.definitions";
import { Trigger } from "./logic/Trigger/Trigger";
import {
  GAME_LIFECYCLE_STATE,
  GAME_TURN_PHASE,
} from "./logic/GameState/GameState.definitions";
import { Character } from "./logic/Character/Character";
import { CHARACTER_QUERY_TYPE } from "./logic/CharacterQuery/CharacterQuery.definitions";
import { CharacterQuery } from "./logic/CharacterQuery/CharacterQuery";
import { SIDE } from "./logic/Position/Position.definitions";

import { GameAction } from "./logic/GameAction/GameAction";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  height: 100vh;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  max-width: 40rem;
  max-height: 30rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 2rem;
`;

// const Log = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   text-align: center;

//   padding: 2rem;
//   gap: 2rem;
// `;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  background: rgba(255, 255, 255, 0.2);
  border: 1px solid white;
  border-radius: 0.25rem;

  padding: 2rem;
  gap: 0.5rem;
`;

function createCharacter(
  character: Partial<Character>
): Character {
  const id = uuidv4();

  if (
    character.position?.side === undefined ||
    character.position?.position === undefined
  ) {
    throw Error("Missing side or position");
  }

  return {
    id: id,
    position: {
      side: character.position.side,
      position: character.position.position,
    },
    image: character.image ?? characterImage,
    attack: character.attack ?? 1,
    health: character.health ?? 1,
    triggers: character.triggers ?? [],
  };
}

const leftQueries: CharacterQuery[] = [
  {
    kind: CHARACTER_QUERY_TYPE.side,
    target: SIDE.left,
  },
  { kind: CHARACTER_QUERY_TYPE.position, target: 0 },
];

const rightQueries: CharacterQuery[] = [
  {
    kind: CHARACTER_QUERY_TYPE.side,
    target: SIDE.right,
  },
  { kind: CHARACTER_QUERY_TYPE.position, target: 0 },
];

const rageTrigger: Trigger = {
  condition: [
    {
      kind: EVENT_FILTER_TYPE.event_kind,
      target: GAME_EVENT_TYPE.attack,
    },
  ],
  then: [
    {
      kind: GAME_ACTION_TYPE.DealDamageAction,
      events: [
        {
          to: rightQueries,
          from: leftQueries,
          amount: 3,
        },
      ],
    },
  ],
};

const App = () => {
  const [appState, setAppState] =
    useState<ApplicationState>({
      resolver: {
        type: "Paused",
      },
      actionQueue: [],
      game: {
        state: GAME_LIFECYCLE_STATE.Ongoing,
        phase: GAME_TURN_PHASE.Upkeep,
        left: [
          createCharacter({
            position: { side: SIDE.left, position: 0 },
          }),
          createCharacter({
            position: { side: SIDE.left, position: 1 },
          }),
          {
            ...createCharacter({
              position: {
                side: SIDE.left,
                position: 2,
              },
            }),
            triggers: [rageTrigger],
          },
        ],
        right: Array.from({ length: 3 }).map((_, index) =>
          createCharacter({
            position: {
              side: SIDE.right,
              position: index,
            },
          })
        ),
        actionQueue: [
          [
            {
              kind: GAME_ACTION_TYPE.AttackAction,
            },
          ],
        ],
      },
      interface: {
        actionQueue: [],
        animations: [],
      },
    });

  const finishStep = useCallback(() => {
    console.log("Finishing step");
    if (appState.resolver.type !== "Running") {
      console.error(
        "Can't step application while it's running!"
      );
      return;
    }

    setAppState({
      ...appState,
      resolver: {
        type: "Paused",
      },
      game: appState.resolver.nextState,
    });
  }, [appState]);

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Navbar />
        <Main>
          <Content>
            <p>{appState.resolver.type}</p>
            <p>
              {appState.game.state} - {appState.game.phase}
            </p>

            <Fight
              ui={appState.interface}
              game={appState.game}
              onFinish={finishStep}
            />

            <Button
              disabled={
                appState.game.state === "Over" ||
                appState.resolver.type !== "Paused" ||
                appState.game.actionQueue.length <= 0
              }
              onClick={() => {
                setAppState((applicationState) =>
                  ApplicationReducer(applicationState, {
                    kind: "Continue",
                  })
                );
              }}
            >
              Continue
            </Button>
            <Summary>
              {appState.game.actionQueue.length ? (
                appState.game.actionQueue[0].map(
                  (a: GameAction, i) => (
                    <p key={i}>{a.kind}</p>
                  )
                )
              ) : (
                <p>"Unknown"</p>
              )}
            </Summary>
            {/*{false && (
              <Log>
                <p>
                  {[
                    appState.game.state === "Over",
                    appState.resolver.type !== "Paused",
                    appState.game.actionQueue.length <= 0,
                  ]
                    .map((c) => "" + c)
                    .join(", ")}
                </p>
                {appState.game.actionQueue
                  .map((a: GameAction[]) =>
                    JSON.stringify(a)
                  )
                  .map((a: GameAction[], i) => (
                    <p key={i}>{a}</p>
                  ))}
              </Log>
            )} */}
          </Content>
        </Main>
      </Wrapper>
    </>
  );
};

export default withAuthenticator(App);
