import { StyleAnimation } from "../views/animation-system";
import { GameEvent } from "../logic/GameEvent/GameEvent";

export const enum InterfaceActionKind {
  Present = "Present",
}

export type InterfacePresentAction = {
  kind: InterfaceActionKind.Present;
  events: GameEvent[];
};

export type InterfaceAction = InterfacePresentAction;

export type InterfaceState = {
  actionQueue: InterfaceAction[];

  // this needs to stop being a flat array,
  // and turn into something more complex
  // for animations at different levels
  // in the dom
  animations: StyleAnimation[];
};
