import { useEffect, useMemo, useState } from "react";
import {
  css,
  Interpolation,
  Keyframes,
} from "styled-components";

export type StyleAnimationTemplate = {
  id: string;
  keyframes: Keyframes;
  timing: string;
  duration: number;
};

export type StyleAnimation = StyleAnimationTemplate & {
  // target specific character or something here somehow.
  start: number;
};

const calculateDelay = (a: StyleAnimation) => `
  ${a.start - Date.now()}ms
`;

const renderStyleAnimation = (a: StyleAnimation) => css`
  ${a.keyframes} ${a.duration}ms ${a.timing} ${calculateDelay(
    a
  )}
`;

function interleave<T>(separator: T, elements: T[]) {
  const interleaved: T[] = [];

  for (let i = 0; i < elements.length; i++) {
    interleaved.push(elements[i]);
    if (i !== elements.length - 1)
      interleaved.push(separator);
  }

  return interleaved;
}

export function renderStyleAnimations<T>(
  animations: StyleAnimation[]
) {
  if (!animations.length) return [];

  return interleave<Interpolation<T> | string>(
    ", ",
    animations.map(renderStyleAnimation)
  );
}

export function useStyleAnimation(
  animations: StyleAnimation[],
  onFinish: () => void
) {
  const [runningAnimations, setRunningAnimations] =
    useState<StyleAnimation[]>([]);

  const compositeAnimationKey = useMemo(
    () => runningAnimations.map((a) => a.id).join("; "),
    [runningAnimations]
  );

  useEffect(() => {
    const remaining = (animation: StyleAnimation) =>
      animation.start + animation.duration - Date.now();

    const running = animations.filter(
      (a) => remaining(a) > 0
    );

    const timeouts: { [id: string]: NodeJS.Timeout } = {};
    setRunningAnimations(running);

    for (let animation of running) {
      timeouts[animation.id] = setTimeout(() => {
        const lastAnimation = animations.sort(
          (a, b) =>
            b.start + b.duration - (a.start + a.duration)
        )[0];

        if (animation === lastAnimation) onFinish();
      }, remaining(animation));
    }

    return () => {
      for (let key in timeouts) {
        clearTimeout(timeouts[key]);
      }
    };
  }, [animations, onFinish]);

  return {
    compositeAnimationKey,
    animations: runningAnimations,
  };
}
