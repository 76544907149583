import { AmplifySignOut } from "@aws-amplify/ui-react";
import React from "react";
import styled from "styled-components";

const Container = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.button`
  display: flex;
  align-items: center;

  padding: 0 2rem;

  background-color: #ff9900;
  font-weight: 700;

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const Navbar = () => (
  <Container>
    <Logo>Grail</Logo>
    <AmplifySignOut />
  </Container>
);

export default Navbar;
