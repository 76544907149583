import { keyframes } from "styled-components";
import { StyleAnimationTemplate } from "./animation-system";

export const bump: StyleAnimationTemplate = {
  id: "bump-start",
  keyframes: keyframes`
      0% { gap: 2rem; }
     20% { gap: 1.8rem; }
     50% { gap: 0; }
     80% { gap: 1.8rem; }
    100% { gap: 2rem; }
  `,
  timing: "linear",
  duration: 500,
};

export const bumpStart: StyleAnimationTemplate = {
  id: "bump-start",
  keyframes: keyframes`
      0% { gap: 2rem; }
     40% { gap: 1.8rem; }
    100% { gap: 0; }
  `,
  timing: "linear",
  duration: 1500,
};

export const bumpEnd: StyleAnimationTemplate = {
  id: "bump-end",
  keyframes: keyframes`
      0% { gap: 0rem; }
     40% { gap: 0.2rem; }
    100% { gap: 2rem; }
`,
  timing: "linear",
  duration: 500,
};

export const big: StyleAnimationTemplate = {
  id: "big",
  keyframes: keyframes`
      0% { transform: scale(1); }
     30% { transform: scale(1.1); }
    100% { transform: scale(1); }
  `,
  timing: "ease-in-out",
  duration: 300,
};

export const shine: StyleAnimationTemplate = {
  id: "shine",
  keyframes: keyframes`
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  `,
  timing: "ease-in-out",
  duration: 500,
};

const Animations = {
  bump,
  bumpStart,
  bumpEnd,
  big,
  shine,
};

export default Animations;
