import React from "react";
import styled from "styled-components";

import {
  StyleAnimation,
  renderStyleAnimations,
  useStyleAnimation,
} from "./animation-system";

import { GameState } from "../logic/GameState/GameState";

import { Battlefield } from "../components/Battlefield/Battlefield";

import { InterfaceState } from "../types/InterfaceState";

const View = styled.div<{
  fight?: boolean;
  animations?: StyleAnimation[];
}>`
  display: flex;
  align-content: center;

  gap: 2rem;

  animation: ${(p) =>
    p.animations
      ? renderStyleAnimations(p.animations)
      : "none"};
`;

const Fight = ({
  ui,
  game,
  onFinish,
}: {
  ui: InterfaceState;
  game: GameState;
  onFinish: () => void;
}) => {
  // animations here needs to become
  // the more complex variant too
  const { compositeAnimationKey, animations } =
    useStyleAnimation(ui.animations, onFinish);

  return (
    <View
      animations={animations}
      key={compositeAnimationKey}
    >
      {/* Break battlefield out
          so we can poke all the fun stuff*/}
      <Battlefield left={game.left} right={game.right} />
    </View>
  );
};

export default Fight;
