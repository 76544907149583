import { GameEvent } from "../GameEvent/GameEvent";
import { EventFilter } from "./GameEventFilter";
import { EVENT_FILTER_TYPE } from "./GameEventFilter.definitions";

export const FilterEvents: (
  event: GameEvent,
  filters: EventFilter[]
) => boolean = (event, filters) => {
  return filters.every((filter) => {
    switch (filter.kind) {
      case EVENT_FILTER_TYPE.event_kind:
        return event.kind === filter.target;
      case EVENT_FILTER_TYPE.game_state:
        return true;
      default:
        throw Error(
          "Attempted to filter invalid filter kind"
        );
    }
  });
};