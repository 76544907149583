import {
  AttackAction,
  DealDamageAction,
  GameAction,
  PerformStateBasedActionsAction,
} from "../GameAction/GameAction";
import { GameEvent } from "../GameEvent/GameEvent";
import { GAME_EVENT_TYPE } from "../GameEvent/GameEvent.definitions";
import { GameState } from "./GameState";
import { GAME_ACTION_TYPE } from "../GameAction/GameAction.definitions";
import { CHARACTER_QUERY_TYPE } from "../CharacterQuery/CharacterQuery.definitions";
import { SIDE } from "../Position/Position.definitions";
import { Character } from "../Character/Character";
import { CharacterReducer } from "../Character/Character.reducer";
import { Query } from "../CharacterQuery/CharacterQuery.operations";
import { CharacterQuery } from "../CharacterQuery/CharacterQuery";

type Mutation<T extends GameAction> = (
  action: T
) => (state: GameState) => MutationResult;

type MutationResult = {
  state: GameState;
  reaction: GameAction[][];
  explanation: GameEvent[];
};

const generateDealDamageAction = (state: GameState) => {
  const left: CharacterQuery[] = [
    { kind: CHARACTER_QUERY_TYPE.side, target: SIDE.left },
    { kind: CHARACTER_QUERY_TYPE.position, target: 0 },
  ];
  const right: CharacterQuery[] = [
    { kind: CHARACTER_QUERY_TYPE.side, target: SIDE.right },
    { kind: CHARACTER_QUERY_TYPE.position, target: 0 },
  ];

  return {
    kind: GAME_ACTION_TYPE.DealDamageAction,
    events: [
      {
        from: left,
        to: right,
        amount: state.left[0].attack,
      },
      {
        from: right,
        to: left,
        amount: state.right[0].attack,
      },
    ],
  };
};

const startCombat: Mutation<AttackAction> =
  (action: AttackAction) => (state: GameState) => {
    const event: GameEvent = {
      kind: GAME_EVENT_TYPE.attack,
    };

    const reducedLeft = state.left.map(CharacterReducer(event));
    const reducedRight = state.right.map(CharacterReducer(event));

    const left: GameAction[][] = reducedLeft.flatMap((c) => c.reaction);
    const right: GameAction[][] = reducedRight.flatMap((c) => c.reaction);

    const both: GameAction[][] = [...left, ...right];

    const reaction: GameAction[][] = [
      ...(both.length > 0 ? both : []),
      [generateDealDamageAction(state)],
    ];

    return {
      state: {
        ...state,
        left: reducedLeft.map((c) => c.state),
        right: reducedRight.map((c) => c.state),
      },
      reaction: reaction,
      explanation: [
        event,
        ...reducedLeft.flatMap((c) => c.explanation),
        ...reducedRight.flatMap((c) => c.explanation),
      ],
    };
  };

const dealDamage: Mutation<DealDamageAction> =
  (action: DealDamageAction) => (state: GameState) => {
    const damage = (character: Character) => ({
      ...character,
      health:
        character.health -
        action.events
          .filter((event) => Query(character, event.to))
          .map((e) => e.amount)
          .reduce((a, b) => a + b, 0),
    });

    return {
      state: {
        ...state,
        left: state.left.map(damage),
        right: state.right.map(damage),
      },
      // TODO: Check triggers!
      reaction: [],
      explanation: [
        {
          kind: GAME_EVENT_TYPE.dealt_damage, //"Dealt damage",
          events: action.events,
        },
      ],
    };
  };

const stateBasedActions: Mutation<PerformStateBasedActionsAction> =
  (action: PerformStateBasedActionsAction) => (state: GameState) => {
    const alive = (c: Character) => c.health > 0;

    const relocate = (character: Character, index: number) => ({
      ...character,
      position: {
        ...character.position,
        position: index,
      },
    });

    const filterDead = (state: GameState) => ({
      state: {
        ...state,
        left: state.left.filter(alive).map(relocate),
        right: state.right.filter(alive).map(relocate),
      },
      reaction: [],
      explanation: [],
    });

    return filterDead(state);
  };

function handleAction(
  action: GameAction
): (state: GameState) => MutationResult {
  switch (action.kind) {
    case GAME_ACTION_TYPE.AttackAction:
      return startCombat(action);
    case GAME_ACTION_TYPE.DealDamageAction:
      return dealDamage(action);
    case GAME_ACTION_TYPE.PerformStateBasedActionsAction:
      return stateBasedActions(action);
    default:
      throw new Error(`Failed to reduce ${action}`);
  }
}

function applyAction(
  current: MutationResult,
  action: (state: GameState) => MutationResult
): MutationResult {
  return {
    state: action(current.state).state,
    reaction: [...action(current.state).reaction, ...current.reaction],
    explanation: [...current.explanation, ...action(current.state).explanation],
  } as MutationResult;
}

function handleActions(state: GameState): MutationResult {
  const result: MutationResult = state.actionQueue[0]
    .flatMap(handleAction)
    .reduce(applyAction, {
      state: {
        ...state,
        actionQueue: state.actionQueue.slice(1),
      },
      reaction: [],
      explanation: [] as GameEvent[],
    });

  return result;
}

const reduce: (state: GameState, actions: GameAction[]) => MutationResult = (
  state,
  actions
) => {
  const reduced = handleActions(state);

  const performStateBasedActionsAction: PerformStateBasedActionsAction = {
    kind: GAME_ACTION_TYPE.PerformStateBasedActionsAction,
  };

  const isStateBasedActions = actions.some(
    (a) => a.kind === GAME_ACTION_TYPE.PerformStateBasedActionsAction
  );
  console.assert(!isStateBasedActions || actions.length === 1);

  const nextActionQueue: GameAction[][] = [];

  if (!isStateBasedActions) {
    nextActionQueue.push([performStateBasedActionsAction]);
  }

  if (reduced.reaction.length > 0) {
    nextActionQueue.push(...reduced.reaction);
  }

  if (state.actionQueue.slice(1).length > 0) {
    nextActionQueue.push(...state.actionQueue.slice(1));
  }

  return {
    ...reduced,
    state: {
      ...reduced.state,
      actionQueue: nextActionQueue,
    },
  };
};

const GameReducer: (state: GameState) => {
  state: GameState;
  explanation: GameEvent[];
} = (state: GameState) => {
  const reduced: MutationResult = state.actionQueue[0]
    ? reduce(state, state.actionQueue[0])
    : {
        state,
        reaction: [],
        explanation: [
          {
            // TODO: more like phase transition, really
            kind: GAME_EVENT_TYPE.game_ended,
          },
        ],
      };

  return reduced;
};

export { GameReducer };
