export enum GAME_LIFECYCLE_STATE {
  Ongoing = "Ongoing",
  Over = "Over",
}

export const GAME_LIFECYCLE_STATES = [
  GAME_LIFECYCLE_STATE.Ongoing,
  GAME_LIFECYCLE_STATE.Over,
] as const;

export type GameLifeCycleState = typeof GAME_LIFECYCLE_STATES[number];

export enum GAME_TURN_PHASE {
  Upkeep = "Upkeep",
  Combat = "Combat",
}

export const GAME_TURN_PHASES = [
  GAME_TURN_PHASE.Upkeep,
  GAME_TURN_PHASE.Combat,
] as const;

export type GameTurnPhase = typeof GAME_TURN_PHASES[number];
